import axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';
import config from './config';
import constants from './constants';
import { getErrorTexts } from './errorTextHelper';
import type { Entity } from '@/types';

// Extend AxiosRequestConfig with custom properties
declare module 'axios' {
  interface AxiosRequestConfig {
    file?: File;
    entity?: Entity;
    description?: string;
    approved?: boolean;
    raw?: boolean;
    ignoreCache?: boolean;
    silenceErrorAlert?: boolean;
    abortKey?: string;
  }
}

export enum HttpStatus {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500,
}

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

/**
 * Keep track of pending requests
 */
export const abortControllers = new Map<string, AbortController>();

async function logout() {
  await store.dispatch('auth/logout');

  if (router.currentRoute.name !== 'Logout') {
    await router.push({
      name: 'Logout',
      query: {
        redirect: router.currentRoute.fullPath,
      },
    });
  }
}

async function checkHealthStatus() {
  if (store.getters.apiHealthStatus === constants.apiHealthStatusSuccess) {
    await store.dispatch('healthCheck/healthCheck');
  }
}

// Modify every request
axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => {
    // Attach access token to request
    const accessToken = store.getters['auth/accessToken'];
    if (accessToken) {
      request.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    // Handle file uploads
    if (request.file) {
      request.headers['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      formData.append('file', request.file);
      if (request.description) {
        formData.append('description', request.description);
      }
      if (request.approved) {
        formData.append('approved', String(request.approved));
      }
      if (request.entity) {
        formData.append('entityType', request.entity.entityType);
        formData.append('entityId', request.entity.entityId);
      }
      request.data = formData;
    }

    // Handle abortable requests
    if (request.abortKey) {
      if (abortControllers.has(request.abortKey)) {
        abortControllers.get(request.abortKey)?.abort();
      }
      const abortController = new AbortController();
      request.signal = abortController.signal;
      abortControllers.set(request.abortKey, abortController);
    }

    return request;
  },
  (error) => Promise.reject(error),
);

// Handle response errors
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response && !response.config.raw ? response.data : response;
  },
  async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const config = error.config;
    const res = error.response;
    const errorTexts = getErrorTexts();

    // 401
    if (res?.status === HttpStatus.UNAUTHORIZED || res?.data?.status === HttpStatus.UNAUTHORIZED) {
      // already tried to refresh.. logout
      if (config._isRetry) return await logout();

      config._isRetry = true;

      if (store.getters['auth/refreshToken']) {
        await store.dispatch('auth/refreshToken');
      }

      if (store.getters['auth/accessToken']) {
        return axiosInstance({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${store.getters['auth/accessToken']}`,
          },
        });
      }
    }

    // 403
    else if (res?.status === HttpStatus.FORBIDDEN) {
      if (router.currentRoute.name !== 'Login') {
        return await logout();
      }
    }

    // 422
    else if (res?.status === HttpStatus.UNPROCESSABLE_ENTITY && res?.data?.code === 'validation_failed') {
      if (!config.silenceErrorAlert) {
        await store.dispatch('setErrorAlert', errorTexts.validation_failed);
      }
    }

    // 500
    else if (res?.status === HttpStatus.SERVER_ERROR || res?.data?.status === HttpStatus.SERVER_ERROR) {
      await store.dispatch('setErrorAlert', errorTexts.server);
    }

    const errorParsed = config.responseType === 'arraybuffer' ? JSON.parse(JSON.stringify(res)) : res?.data || res;
    if (errorParsed) {
      await checkHealthStatus();
      throw errorParsed;
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
