<template>
  <component :is="layout" ref="app" :success-message="successMessage" @success:dismiss="clearSuccessMessage" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import * as Sentry from '@sentry/vue';
import FeedbackButton from '@/modules/FeedbackButton.vue';
import { LAYOUT } from '@/types';
import { trackLoginEvent } from './plugins/gtm';

export default defineComponent({
  components: {
    FeedbackButton,
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    successMessage(): string {
      return this.$store.getters.successMessage;
    },
    layout(): LAYOUT {
      return this.$route.meta?.layout ?? LAYOUT.default;
    },
  },
  watch: {
    user(val) {
      if (val?.id) {
        Sentry.setUser({ id: val.id });
      } else {
        Sentry.getCurrentScope().setUser(null);
      }
    },
    $route() {
      if (!this.$route.query.bulkFileCreate) {
        this.selectFiles([]);
      }
    },
  },
  mounted() {
    this.$store.dispatch('clearErrorAlert');

    const user = this.$store.getters.getUser;
    if (user) {
      trackLoginEvent();
    }
  },
  methods: {
    ...mapMutations({
      selectFiles: 'accounting/setBulkActionFiles',
    }),
    clearSuccessMessage() {
      this.$store.dispatch('clearSuccessMessage');
    },
  },
});
</script>
