import api from '@/utils/api';

export enum ReportModule {
  SUB_ACCOUNTS,
  SUB_ACCOUNTS_AGING,
  BALANCE_SHEET,
  TRIAL_BALANCE,
  INCOME_STATEMENT,
  GENERAL_LEDGER,
  COMPILATION_REPORT,
  VAT_REPORT,
  TERM_SETTLEMENT,
  BANK_RECONCILIATION,
  TIMESHEET,
  VOUCHERS,
  VAT_SPEC_OVERVIEW,
  VAT_SPEC_TRANSACTIONS,
  INCOME_BALANCE,
}

type DownloadArgs = {
  module: ReportModule;
  companyId: string;
  format: ReportFormat;
  queryParams?: Record<string, any>;
  routeParams?: Record<string, any>;
  body?: Record<string, any>;
  method?: 'get' | 'post';
};

type EndpointFn = (routeParams: any) => string;

export type ReportFormat = 'PDF' | 'EXCEL' | 'CSV' | 'XML' | 'ZIP';

const endpoints: Record<ReportModule, string | EndpointFn> = {
  [ReportModule.SUB_ACCOUNTS]: 'sub_accounts_v2/report/download',
  [ReportModule.SUB_ACCOUNTS_AGING]: 'aging_report/download',
  [ReportModule.BALANCE_SHEET]: 'balance_sheet/download',
  [ReportModule.TRIAL_BALANCE]: 'trial_balance/download',
  [ReportModule.INCOME_STATEMENT]: 'income_statement/download',
  [ReportModule.GENERAL_LEDGER]: 'general_ledger_v2/download',
  [ReportModule.COMPILATION_REPORT]: 'compilation_report/download',
  [ReportModule.VAT_REPORT]: ({ fileId }) => `files/${fileId}/download`,
  [ReportModule.TERM_SETTLEMENT]: 'term_settlement/download',
  [ReportModule.BANK_RECONCILIATION]: ({ bankAccountId }) => `bankreconciliations/${bankAccountId}/download`,
  [ReportModule.TIMESHEET]: 'timesheets/download',
  [ReportModule.VOUCHERS]: 'vouchers/download',
  [ReportModule.VAT_SPEC_OVERVIEW]: 'vat_overview/specification/download',
  [ReportModule.VAT_SPEC_TRANSACTIONS]: 'vat_overview/transactions/download',
  [ReportModule.INCOME_BALANCE]: 'financial_statement/download',
};

export const downloadReportService = {
  async download({
    module,
    companyId,
    format,
    routeParams,
    queryParams,
    body,
    method = 'get',
  }: DownloadArgs): Promise<void> {
    const endpoint =
      typeof endpoints[module] === 'string' ? endpoints[module] : (endpoints[module] as EndpointFn)(routeParams);

    const { data, headers } = await api({
      method,
      url: `companies/${companyId}/${endpoint}`,
      params: { format, ...queryParams },
      data: body,
      responseType: 'blob',
      raw: true,
    });
    const [, filename] = headers['content-disposition'].split(';');

    const blob = new Blob([data], { type: headers['content-type'] });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = decodeURIComponent(filename.split('=').pop());
    link.click();

    URL.revokeObjectURL(link.href);
  },
};
