import { FileService } from '@/services/fileService';

const getDefaultState = () => ({
  file: null, // File object
  fileId: null,
  attachmentMetaData: {
    id: null,
    name: '',
    mediaType: '',
    type: '',
    createdAt: null,
    downloadUrl: '',
  },
  isUploading: false,
  rawData: null,
  isVisible: false,
});

const state = getDefaultState;

const actions = {
  async uploadAttachment({ commit, dispatch }, { companyId, file, entity, skipReset, skipDownload }) {
    commit('setUploading', true);

    try {
      const uploadedFileMetaData = await FileService.uploadFile(companyId, file, entity);

      if (!skipDownload) {
        commit('updateFileData', uploadedFileMetaData);
        if (uploadedFileMetaData.mediaType === 'application/pdf') {
          dispatch('getRawData', { fileId: uploadedFileMetaData.id, companyId }); /* lazy loading of the rawData */
        }
        !skipReset && commit('resetToDefault');
      }

      return uploadedFileMetaData;
    } finally {
      commit('setUploading', false);
    }
  },
  async getRawData({ commit }, { fileId, companyId }) {
    const rawData = await FileService.downloadFile(companyId, fileId);
    commit('updateRawData', rawData);
    return rawData;
  },
  async deleteAttachment({ commit }) {
    commit('resetToDefault');
  },
  resetData({ commit }) {
    commit('resetToDefault');
  },
};

const mutations = {
  setUploading(state, uploading) {
    state.isUploading = uploading;
  },
  setFile(state, file) {
    state.file = file;
  },
  setIsVisible(state, isVisible) {
    state.isVisible = isVisible;
  },
  updateFileData(state, metaData) {
    state.fileId = metaData.id;
    state.attachmentMetaData = metaData;
  },
  updateRawData(state, rawData) {
    state.rawData = rawData;
  },
  resetToDefault(state) {
    const isVisible = state.isVisible;
    Object.assign(state, { ...getDefaultState(), isVisible });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
