<template>
  <ResizableSidebar>
    <template #title>
      {{ attachmentMetaData.name }}
    </template>
    <template #prepend-controls>
      <v-tooltip :disabled="canDelete" max-width="400" bottom transition="fade-transition">
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              v-if="(entityType && entityId) || isOpeningBalance"
              :disabled="!canDelete"
              icon
              @click="deleteAttachmentDialog = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Det er ikke tillatt å slette dette vedlegget.</span>
      </v-tooltip>
      <BaseDialog v-model="deleteAttachmentDialog" width="500">
        <DeleteOpeningBalanceDialog
          v-if="isOpeningBalance"
          @cancel="deleteAttachmentDialog = false"
          @deleted="onAttachmentDeleted"
        />
        <DeleteAttachmentDialog
          v-else-if="entityId && entityType"
          :entity-id="entityId"
          :entity-type="entityType"
          :file-id="fileId"
          @cancel="deleteAttachmentDialog = false"
          @deleted="onAttachmentDeleted"
        />
      </BaseDialog>
      <v-btn icon @click="openInPopupWindow">
        <v-icon>mdi-dock-window</v-icon>
      </v-btn>
    </template>
    <template #content>
      <v-progress-circular v-if="loading" indeterminate color="primary" />
      <PDFPreview v-else-if="rawData" :raw-data="rawData" />
    </template>
  </ResizableSidebar>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import PDFPreview from '@/components/PDFPreview.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import { getTextByError } from '@/utils/errorTextHelper';
import { handleError } from '@/utils/shared';
import ResizableSidebar from './ResizableSidebar.vue';
import DeleteAttachmentDialog from './DeleteAttachmentDialog.vue';
import DeleteOpeningBalanceDialog from './DeleteOpeningBalanceDialog.vue';
import type { Entity } from '@/types';

export default defineComponent({
  components: {
    PDFPreview,
    ResizableSidebar,
    BaseDialog,
    DeleteAttachmentDialog,
    DeleteOpeningBalanceDialog,
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      loading: true,
      rawData: null,
      deleteAttachmentDialog: false,
      popupWindow: null as WindowProxy | null,
    };
  },
  computed: {
    ...mapState('attachment', {
      attachmentMetaData: (state: any) => state.attachmentMetaData,
    }),
    entityType() {
      const entityTypeSource = this.$route.meta?.entityTypeSource ?? 'routeMeta';
      const entityTypeRef = this.$route.meta?.entityTypeRef;

      const entityType =
        entityTypeSource === 'query' ? this.$route.query[entityTypeRef!] : this.$route.meta?.entityType;

      return entityType as Entity['entityType'] | undefined;
    },
    entityId() {
      const entityIdSource = this.$route.meta?.entityIdSource ?? 'params';
      const entityIdRef = this.$route.meta?.entityIdRef;
      const entityId = entityIdRef && this.$route[entityIdSource]?.[entityIdRef];

      return entityId as Entity['entityId'] | undefined;
    },
    isOpeningBalance() {
      return this.$route.name === 'OpeningBalance';
    },
    canDelete() {
      return this.entityType !== 'INVOICE';
    },
  },
  watch: {
    fileId: {
      handler() {
        this.loadAttachment();
      },
      immediate: true,
    },
  },
  destroyed() {
    this.$store.dispatch('attachment/resetAttachmentMetaData');

    if (this.popupWindow) {
      this.popupWindow?.close();
      this.$store.commit('theme/setAttachmentViewerMinimized', false);
    }
  },
  methods: {
    openInPopupWindow() {
      if (this.popupWindow) {
        this.updatePopupWindowFileId(this.fileId);
        this.popupWindow.focus();
        return;
      }

      const { width, height } = window.screen;
      const { params, query } = this.$route;

      this.popupWindow = window.open(
        `/${params?.companyNameSlug}/previewFile?fileId=${query.fileId}`,
        '',
        `width=${width / 2},height=${height}`,
      );

      this.$store.commit('theme/setAttachmentViewerMinimized', true);

      const interval = setInterval(() => {
        if (this.popupWindow?.closed) {
          clearInterval(interval);
          this.popupWindow = null;
          this.$store.commit('theme/setAttachmentViewerMinimized', false);
        }
      }, 500);
    },
    async loadAttachment() {
      if (!this.companyId || !this.fileId) return;

      try {
        this.loading = true;

        await this.$store.dispatch('attachment/getAttachment', {
          companyId: this.companyId,
          attachmentId: this.fileId,
        });

        this.rawData = await this.$store.dispatch('attachment/downloadAttachment', {
          companyId: this.companyId,
          attachmentId: this.fileId,
        });

        if (this.popupWindow) {
          this.updatePopupWindowFileId(this.fileId);
        }
      } catch (err: any) {
        this.$store.dispatch('setErrorAlert', getTextByError(err));
        handleError(err);
      } finally {
        this.loading = false;
      }
    },
    onAttachmentDeleted({ fileId }: { fileId: string }) {
      this.deleteAttachmentDialog = false;
      this.$router.replace({
        query: {
          ...this.$route.query,
          fileId: undefined,
        },
      });
      this.popupWindow?.close();
      this.$eventBus.emit('attachmentDeleted', { fileId });
    },
    updatePopupWindowFileId(fileId: string) {
      this.popupWindow?.app.$router.replace({
        query: {
          fileId,
        },
      });
    },
  },
});
</script>
