<template>
  <ResizableSidebar>
    <template v-if="file" #title>
      {{ formatFileName(file) }}
    </template>
    <template #prepend-controls>
      <v-btn v-if="file && !fileIsUploading" icon @click="reset">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template #content>
      <v-progress-circular v-if="fileIsUploading" indeterminate color="primary" />
      <template v-else>
        <FilePreview v-if="file" :file="file" />
        <FileDropzone v-else :max-files="1" @update:files="updateFiles" />
      </template>
    </template>
  </ResizableSidebar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapMutations } from 'vuex';
import { FileDropzone, FilePreview } from '@/components/files';
import { formatFileName } from '@/components/files/utils';
import ResizableSidebar from './ResizableSidebar.vue';
import { getTextByError } from '@/utils/errorTextHelper';
import type { Entity } from '@/types';

export default defineComponent({
  components: {
    ResizableSidebar,
    FileDropzone,
    FilePreview,
  },
  computed: {
    file() {
      return this.$store.state.newAttachment.file;
    },
    fileIsUploading() {
      return this.$store.state.newAttachment.isUploading;
    },
    entityType() {
      const entityTypeSource = this.$route.meta?.entityTypeSource ?? 'routeMeta';
      const entityTypeRef = this.$route.meta?.entityTypeRef;

      const entityType =
        entityTypeSource === 'query' ? this.$route.query[entityTypeRef!] : this.$route.meta?.entityType;

      return entityType as Entity['entityType'] | undefined;
    },
    entityId() {
      const entityIdSource = this.$route.meta?.entityIdSource ?? 'params';
      const entityIdRef = this.$route.meta?.entityIdRef;
      const entityId = entityIdRef && this.$route[entityIdSource]?.[entityIdRef];

      return entityId as Entity['entityId'] | undefined;
    },
    isBankReconciliation() {
      return this.$route.name === 'BankReconciliationShow';
    },
    isManualTransaction() {
      return this.$route.name === 'AccountingManualTransactions';
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        // Reset the previewed attachment when the route changes, except when navigating between specific routes
        const excludedRouteNames = ['AccountingManualTransactions', 'PurchaseCreate', 'SaleCreate'];

        // from route will be undefined when the user first loads the page
        const fromRouteExcluded = !from || excludedRouteNames.includes(from.name);
        const toRouteExcluded = excludedRouteNames.includes(to.name);
        const skipAttachmentReset = fromRouteExcluded && toRouteExcluded;

        // when switching from AccountingManualTransactions to PurchaseCreate | SaleCreate, we want to upload temp file
        if (from?.name === 'AccountingManualTransactions' && ['PurchaseCreate', 'SaleCreate'].includes(to.name)) {
          this.file && this.updateFiles([this.file]);
        }

        !skipAttachmentReset && this.$store.dispatch('newAttachment/deleteAttachment');
      },
      deep: true,
      immediate: true,
    },
  },
  destroyed() {
    this.$store.dispatch('newAttachment/deleteAttachment');
  },
  methods: {
    ...mapMutations({
      // Keep the file in-memory
      setFile: 'newAttachment/setFile',
    }),
    ...mapActions({
      deleteAttachment: 'newAttachment/deleteAttachment',
    }),
    async updateFiles(files: any[]) {
      const file = files[0] ?? null;
      if (this.isManualTransaction) {
        return this.setFile(file);
      }

      const fileShouldBeUploaded = ['PurchaseCreate', 'SaleCreate'].includes(this.$route.name as string);

      // Auto-upload attachment (when editing existing transactions)
      if ((file && this.entityId && this.entityType) || fileShouldBeUploaded) {
        try {
          // Uploading a CSV file for bank reconciliation should not be treated as an attachment
          const isBankRecCSV = this.isBankReconciliation && (file.type === 'text/csv' || file.type === 'text/plain');

          const fileMetadata = await this.$store.dispatch('newAttachment/uploadAttachment', {
            skipDownload: isBankRecCSV,
            companyId: this.companyId,
            file,
            entity: {
              entityId: this.entityId,
              entityType: this.entityType,
            },
          });

          if (!isBankRecCSV) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                fileId: fileMetadata.id,
              },
            });
          }

          isBankRecCSV
            ? this.$eventBus.emit('bankRecCSVUploaded', fileMetadata)
            : this.$eventBus.emit('attachmentUploaded', fileMetadata);
        } catch (e: any) {
          this.$store.dispatch('setErrorAlert', getTextByError(e));
          this.$sentry.captureException(e);
        }
      } else {
        this.setFile(file);
      }
    },
    reset() {
      this.deleteAttachment();
    },
    formatFileName,
  },
});
</script>
