import mitt from 'mitt';
import type { FileResponse } from '@/types/schema';

type Events = {
  attachmentUploaded: FileResponse;
  attachmentDeleted: { fileId: string };
  bankRecCSVUploaded: FileResponse;
};

export default mitt<Events>();
