<template>
  <v-navigation-drawer
    id="appNavDrawer"
    ref="navDrawer"
    :value="isNavDrawerVisible"
    :dark="false"
    :disable-resize-watcher="false"
    :expand-on-hover.sync="isNavDrawerMinimized"
    :mobile-breakpoint="960"
    app
    floating
    color="white"
    height="100%"
    class="shadow-sm rounded-tr-lg rounded-br-lg"
    @transitionend="transitionend"
  >
    <v-list dense nav>
      <v-list-item style="min-height: 60px" class="px-0 pt-2">
        <v-list-item-avatar class="mr-0" style="border-radius: 0">
          <img style="height: 40px; width: 32px" src="/assets/systima.png" @click="onLogoClick" />
        </v-list-item-avatar>
        <v-list-item-title class="text-18 text-uppercase text-default">
          <img style="position: relative; left: -36px" src="/assets/logo.png" @click="onLogoClick" />
        </v-list-item-title>
        <v-scroll-x-transition>
          <v-btn icon @click.stop="toggleSidebar">
            <v-tooltip v-if="!isNavDrawerMinimized" right color="primary">
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" color v-on="on">mdi-circle-slice-8</v-icon>
              </template>
              <span>Unpin</span>
            </v-tooltip>
            <v-tooltip v-if="isNavDrawerMinimized" right color="primary">
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" color v-on="on">mdi-circle-outline</v-icon>
              </template>
              <span>Pin</span>
            </v-tooltip>
          </v-btn>
        </v-scroll-x-transition>
      </v-list-item>
    </v-list>

    <v-list class="py-0">
      <template v-for="(item, index) in itemsFiltered">
        <ListGroup v-if="item.items && item.items.length" :key="`group-${index}`" :item="item" />
        <ListItem v-else :key="`item-${index}`" :item="item" />
      </template>
    </v-list>

    <template v-if="!$store.state.theme.isNavDrawerMinimized || showUserInfo" #append>
      <div class="px-3 py-5 text-no-wrap">
        <div class="text-16 mb-1">{{ fullName }}</div>
        <v-btn to="/profile" small icon class="mr-2">
          <v-icon small>mdi-cog</v-icon>
        </v-btn>
        <v-btn to="/logout" small icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ListGroup from './ListGroup.vue';
import ListItem from './ListItem.vue';
import { SubscriptionModule } from '@/types';
import type { Location } from 'vue-router';
import type { UserResponse } from '@/types/schema';

type SubscriptionResponse = {
  period: string;
  price: number;
  validFrom: string;
  subscriptionPlan: {
    id: string;
    code: string;
    name: string;
    modules: SubscriptionModule[];
  };
};

type MenuItem = {
  text: string;
  icon?: string;
  color?: string;
  to?: string | Location;
  company?: boolean;
  modules?: SubscriptionModule[];
  systemRole?: UserResponse['systemRole'];
  exact?: boolean;
  items?: MenuItem[];
};

export default defineComponent({
  components: {
    ListGroup,
    ListItem,
  },
  data: () => ({
    opened: true,
    loading: true,
    showUserInfo: false,
  }),
  computed: {
    ...mapState('subscription', {
      currentSubscription: (state: any) => state.currentSubscription,
    }),
    ...mapGetters({
      advancedMode: 'theme/getAdvancedMode',
      selectedCompany: 'selectedCompany',
      companyIsOnboarded: 'bankIntegration/getCompanyOnboardingStatus',
      isNavDrawerMinimized: 'theme/getNavDrawerMinimized',
      isNavDrawerVisible: 'theme/getNavDrawerVisible',
    }),
    subscription() {
      return this.currentSubscription[this.selectedCompany.id] as SubscriptionResponse | undefined;
    },
    companies() {
      return this.$store.getters.companies as any[];
    },
    company() {
      return this.$store.getters.getCompanyById(this.companyId);
    },
    companyNameSlug() {
      return this.$store.getters.getCompanyNameSlugById(this.companyId);
    },
    user() {
      return this.$store.getters.getUser as UserResponse;
    },
    fullName() {
      return `${this.user?.firstName} ${this.user?.lastName}`;
    },
    items(): MenuItem[] {
      if (!this.$route.params.companyNameSlug) {
        return [
          ...(this.$route.name !== 'SelectCompany'
            ? [
                {
                  text: 'Tilbake til selskaper',
                  icon: 'mdi-arrow-left',
                  color: 'grey',
                  to: { name: 'SelectCompany' },
                  exact: true,
                },
              ]
            : []),
          {
            text: 'Innstillinger',
            icon: 'mdi-cog',
            color: 'grey',
            items: [{ text: 'Min profil', to: '/profile', icon: 'mdi-circle-medium' }],
          },
          ...(this.user?.accountingFirmId
            ? [
                {
                  text: 'Mitt regnskapsbyrå',
                  icon: 'mdi-domain-plus',
                  color: 'grey',
                  to: { name: 'AccountingFirmCustomers' },
                },
              ]
            : []),
          ...(this.user?.systemRole !== 'COMMON'
            ? [
                {
                  text: 'Systima CP',
                  icon: 'mdi-cog',
                  color: 'grey',
                  to: '/cp',
                },
              ]
            : []),
        ];
      }

      if (this.advancedMode) {
        const menu: MenuItem[] = [
          {
            text: 'Oversikt',
            icon: 'mdi-home',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Bokføring',
            icon: 'mdi-book-plus-outline',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Bokfør godkjente filer',
                to: {
                  name: 'PurchaseBulkCreate',
                  params: { companyNameSlug: this.companyNameSlug },
                  query: {
                    // preserve query params if already on the page (skips navigation basically)
                    ...(this.$route.name === 'PurchaseBulkCreate' ? { ...this.$route.query } : {}),
                  },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Bokfør andre filer',
                to: { name: 'PurchaseCreate', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Filmottak',
                to: {
                  name: 'FileInbox',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Mva. rapport',
                to: { name: 'MVAReportIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
          {
            text: 'Bankavstemming',
            icon: 'mdi-human-male-board-poll',
            to: { name: 'BankReconciliationIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple',
            color: 'amber',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube',
            color: 'purple',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account',
            color: 'amber',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            color: 'amber',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Balanserapport',
                to: {
                  name: 'BalanceReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Bilagsjournal',
                to: { name: 'Vouchers', params: { companyNameSlug: this.companyNameSlug } },
              },
              {
                text: 'Feriepengeliste',
                to: { name: 'HolidayPay', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Hovedbok',
                to: { name: 'GeneralLedgerIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Mva. spesifikasjon',
                to: { name: 'VatSpecification', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Reskontro',
                to: { name: 'SubAccounts', params: { companyNameSlug: this.companyNameSlug, type: 'supplier' } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Resultatrapport',
                to: {
                  name: 'IncomeReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Saldobalanse',
                to: { name: 'TrialBalanceIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Sammenstillingsrapport',
                to: { name: 'CompilationReport', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
            ],
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog',
            color: 'grey',
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Bankavstemninginnst',
                to: { name: 'BankReconciliationSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Betalingsinnstillinger',
                to: { name: 'PaymentSettingsIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Fakturainnstillinger',
                to: {
                  name: 'InvoiceSettings',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
              },
              {
                text: 'Integrasjoner',
                to: { name: 'IntegrationsSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Kontoplan',
                to: { name: 'AccountingAccountsIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Lønnsinnstillinger',
                to: { name: 'SalarySettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Låste perioder',
                to: { name: 'LockPeriodSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              { text: 'Min profil', to: '/profile' },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Timeføringsinnstillinger',
                to: { name: 'TimesheetsSettings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Åpningsbalanse',
                to: { name: 'OpeningBalance', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-domain-plus',
            color: 'grey',
            to: { name: 'AccountingFirmCustomers' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            icon: 'mdi-cog',
            color: 'grey',
            to: '/cp',
          });
        }
        if (this.companyIsOnboarded) {
          menu.splice(2, 0, {
            text: 'Betalinger',
            icon: 'mdi-cash-multiple',
            to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            exact: true,
            modules: [SubscriptionModule.ACCOUNTING],
          });
        }
        return menu;
      } else {
        const menu: MenuItem[] = [
          {
            text: 'Oversikt',
            icon: 'mdi-home',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Filmottak',
            icon: 'mdi-book-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'FileInbox', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.ACCOUNTING],
          },
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple',
            color: 'amber',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube',
            color: 'purple',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account',
            color: 'amber',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            color: 'amber',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Balanserapport',
                to: {
                  name: 'BalanceReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Resultatrapport',
                to: {
                  name: 'IncomeReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog',
            color: 'grey',
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              {
                text: 'Fakturainnstillinger',
                to: {
                  name: 'InvoiceSettings',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
              },
              { text: 'Min profil', to: { name: 'Profile' } },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE],
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-domain-plus',
            color: 'grey',
            to: { name: 'AccountingFirmCustomers' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            icon: 'mdi-cog',
            color: 'grey',
            to: '/cp',
          });
        }
        if (this.companyIsOnboarded) {
          menu.splice(3, 0, {
            text: 'Betalinger',
            icon: 'mdi-cash-multiple',
            to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            exact: true,
            modules: [SubscriptionModule.ACCOUNTING],
          });
        }
        return menu;
      }
    },
    itemsFiltered(): MenuItem[] {
      const modules: any[] = this.subscription?.subscriptionPlan.modules || [];

      const filterFn = (item: MenuItem) => {
        const hasCompanies = !item.company || this.companies.length;
        const hasModule = !item.modules || modules.some((module) => item.modules?.includes(module));
        const hasRole = !item.systemRole || this.user?.systemRole === item.systemRole;

        if (item.items) {
          item.items = item.items.filter(filterFn);
        }

        return hasCompanies && hasModule && hasRole;
      };

      const items = JSON.parse(JSON.stringify(this.items));

      return items.filter(filterFn);
    },
  },
  methods: {
    onLogoClick() {
      if (this.companyNameSlug) {
        this.currentSubscription[this.company.id] &&
          this.$router.push({ name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } });
      } else {
        this.$router.push({ name: 'SelectCompany' });
      }
    },
    toggleSidebar() {
      this.$store.dispatch('theme/toggleNavDrawerMinimized');
    },
    transitionend() {
      (document.getElementById('appNavDrawer') as HTMLElement).offsetWidth > 56
        ? (this.showUserInfo = true)
        : (this.showUserInfo = false);
    },
  },
});
</script>

<style lang="scss">
.nav-drawer--minimized {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    padding: 110px 0px 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }
  &.nav-drawer--visible {
    .v-toolbar {
      left: 56px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}
</style>
